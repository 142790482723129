import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import HostelAmenity from '@/components/Hostel/Amenity'
import HostelFacility from '@/components/Hostel/Facility'
import HostelGuide from '@/components/Hostel/Guide'
import HostelInformation from '@/components/Hostel/Information'
import HostelLounge from '@/components/Hostel/Lounge'
import HostelMain from '@/components/Hostel/Main'
import HostelRoom from '@/components/Hostel/Room'
import Layout from '@/components/Layout'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const HostelPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true} dark={true}>
      <main>
        <PageTitle
          title='泊まる｡'
          page='hostel'
          curtain={false}
          crumbs={crumbs}
          crumbLabel='Hostel'
        />
        <HostelMain />
        <HostelGuide />
        <HostelRoom name='megane' />
        <HostelRoom name='washi' />
        <HostelRoom name='urushi' />
        <HostelRoom name='tsutsuji' />
        <HostelAmenity />
        <HostelFacility />
        <HostelLounge />
        <HostelInformation />
      </main>
    </Layout>
  )
}

export default HostelPage

export const Head: HeadFC = ({ location }) => (
  <SEO title='泊まる｡' pathname={location.pathname} page='hostel' />
)
