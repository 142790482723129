// extracted by mini-css-extract-plugin
export var box = "Room-module--box--R6R07";
export var description = "Room-module--description--DE7im";
export var fadeDown = "Room-module--fadeDown--zu0Xg";
export var fadeUp = "Room-module--fadeUp--iEVYt";
export var flowing = "Room-module--flowing--nFBNo";
export var info = "Room-module--info--ENB8S";
export var label = "Room-module--label--TifCm";
export var lineIn = "Room-module--lineIn--5ayLQ";
export var megane = "Room-module--megane--21hd-";
export var meganeSign = "Room-module--meganeSign--ypJyQ";
export var price = "Room-module--price--Wr3Rm";
export var priceItem = "Room-module--priceItem--N4HJW";
export var priceWrap = "Room-module--priceWrap--0QUbG";
export var room = "Room-module--room--BJQV2";
export var table = "Room-module--table--wmHi-";
export var tag = "Room-module--tag--XuWnF";
export var title = "Room-module--title---ZQk7";
export var titleAnim = "Room-module--titleAnim--SMze2";
export var titleWrap = "Room-module--titleWrap--GWLyt";
export var tsutsuji = "Room-module--tsutsuji--SeHiS";
export var tsutsujiSign = "Room-module--tsutsujiSign--F5Eg8";
export var urushi = "Room-module--urushi--qvqxL";
export var urushiSign = "Room-module--urushiSign--sBJUk";
export var washi = "Room-module--washi--ys7b-";
export var washiSign = "Room-module--washiSign--cEo-z";