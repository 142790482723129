// extracted by mini-css-extract-plugin
export var container = "Facility-module--container--8gT8S";
export var description = "Facility-module--description--zWWNI";
export var facility = "Facility-module--facility--LL-6x";
export var fadeDown = "Facility-module--fadeDown--MTSnI";
export var fadeUp = "Facility-module--fadeUp--rrI9z";
export var flowing = "Facility-module--flowing--cXOJ6";
export var icon = "Facility-module--icon--Gr2T3";
export var item = "Facility-module--item--+3gMf";
export var lineIn = "Facility-module--lineIn--2NJY2";
export var name = "Facility-module--name--QZR0q";
export var other = "Facility-module--other--c4y9n";
export var square = "Facility-module--square--+P6FX";
export var title = "Facility-module--title--FVPh8";