import * as React from 'react'

const OvalLeft = () => {
  const width = 303.5
  const height = 334.8
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0'
      height='0'
      viewBox={`0 0 ${width} ${height}`}
    >
      <clipPath id='ovalLeft' clipPathUnits='objectBoundingBox'>
        <path
          transform={`scale(${1 / width}, ${1 / height})`}
          d='M301.82 167.89c-5.2 45.69-24.61 85.24-58.18 118.67-34.16 32.18-71.79 48.26-112.89 48.26s-73.82-16.4-99.88-49.21C5.98 252.79-3.85 213.57 1.35 167.89 7.13 123.5 27.11 84.26 61.28 50.17 94.84 16.71 132.19 0 173.31 0s75.25 16.4 100.74 49.19c24.31 32.18 33.56 71.74 27.78 118.7Z'
        />
      </clipPath>
    </svg>
  )
}

export default OvalLeft
