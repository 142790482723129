// extracted by mini-css-extract-plugin
export var arrow = "Guide-module--arrow--4rqwd";
export var attention = "Guide-module--attention--D7Qc2";
export var container = "Guide-module--container--TkvEt";
export var desktop = "Guide-module--desktop--hq8Ps";
export var fadeDown = "Guide-module--fadeDown--SjWDQ";
export var fadeUp = "Guide-module--fadeUp--vyqi6";
export var flowing = "Guide-module--flowing--EJ4Uc";
export var guide = "Guide-module--guide--CYbwD";
export var item = "Guide-module--item--H+XOL";
export var lineIn = "Guide-module--lineIn--kgpYu";
export var link = "Guide-module--link--knFMN";
export var meganeLetter = "Guide-module--meganeLetter--huAMQ";
export var meganeSign = "Guide-module--meganeSign--uKjnJ";
export var reception = "Guide-module--reception--YhIGz";
export var title = "Guide-module--title--bkVj0";
export var tsutsujiLetter = "Guide-module--tsutsujiLetter--Beytq";
export var tsutsujiSign = "Guide-module--tsutsujiSign--sgDiH";
export var urushiLetter = "Guide-module--urushiLetter--vhqSh";
export var urushiSign = "Guide-module--urushiSign--GNyw+";
export var washiLetter = "Guide-module--washiLetter--f94SY";
export var washiSign = "Guide-module--washiSign--7d2X-";