// extracted by mini-css-extract-plugin
export var animOvalLeft = "Main-module--animOvalLeft--w+Nb9";
export var container = "Main-module--container--ZB+RH";
export var description = "Main-module--description--AS5Av";
export var fadeDown = "Main-module--fadeDown--MA0uK";
export var fadeUp = "Main-module--fadeUp--52s87";
export var flowing = "Main-module--flowing--qRqHi";
export var lineIn = "Main-module--lineIn--+NvSD";
export var main = "Main-module--main--gYnT2";
export var ovalImgWrap = "Main-module--ovalImgWrap--vgNtw";
export var ovalLeftImg = "Main-module--ovalLeftImg--U6pHi";
export var ovalLeftImgWrap = "Main-module--ovalLeftImgWrap--lwXDH";
export var title = "Main-module--title--V+p3H";