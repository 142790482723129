import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Hostel/Main/Main.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import OvalLeft from '@/components/Path/OvalLeft'
import { useIntersection } from '@/hooks/useIntersection'

const HostelMain = () => {
  const ovalImgWrapRef = React.useRef<HTMLDivElement>(null)
  const viewOvalImgWrap = useIntersection({
    target: ovalImgWrapRef,
    margin: '-40% 0%',
  })

  return (
    <section className={styles['main']}>
      <div className={styles['ovalImgWrap']}>
        <OvalLeft />
        <div
          className={`${styles['ovalLeftImgWrap']} ${
            viewOvalImgWrap ? styles['animOvalLeft'] : undefined
          }`}
          ref={ovalImgWrapRef}
        >
          <StaticImage
            className={styles['ovalLeftImg']}
            src='../../../images/hostel/concept.png'
            alt='Concept'
            placeholder='blurred'
          />
        </div>
      </div>
      <div className={styles['container']}>
        <h2 className={styles['title']}>
          思わずほっとする、
          <br />
          伝統と癒やしの空間。
        </h2>
        <p className={styles['description']}>
          ひし形の木の板を組み合わせた六角形模様の床や、
          <MediaBreak media='desktop' />
          和紙畳、越前和紙が使われたお部屋たち。
          <br />
          <br />
          少しでも日本の文化、そしてこの地域を感じてほしい。
          <MediaBreak media='desktop' />
          そんな想いが込められています。
          <br />
          <br />
          木のぬくもりも感じられる空間で、
          <br />
          ちょっとした非日常を堪能してみてください。
        </p>
      </div>
    </section>
  )
}

export default HostelMain
