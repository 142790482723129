import * as React from 'react'

import * as styles from '@/components/Hostel/Amenity/Amenity.module.scss'
import bathIcon from '@/images/icons/bath.svg'
import hairdryerIcon from '@/images/icons/hairdryer.svg'
import slipperIcon from '@/images/icons/slipper.svg'
import toothbrushIcon from '@/images/icons/toothbrush.svg'
import towelIcon from '@/images/icons/towel.svg'

const HostelAmenity = () => {
  return (
    <section className={styles['amenity']}>
      <h3 className={styles['title']}>amenity</h3>
      <p className={styles['description']}>
        宿泊のお客様にはアメニティグッズをご用意しています。
      </p>
      <div className={styles['container']}>
        <div className={styles['item']}>
          <div className={styles['circle']}>
            <img className={styles['icon']} src={towelIcon} alt='towelIcon' />
          </div>
          <span className={styles['name']}>タオル</span>
        </div>
        <div className={styles['item']}>
          <div className={styles['circle']}>
            <img
              className={styles['icon']}
              src={slipperIcon}
              alt='slipperIcon'
            />
          </div>
          <span className={styles['name']}>スリッパ</span>
        </div>
        <div className={styles['item']}>
          <div className={styles['circle']}>
            <img
              className={styles['icon']}
              src={hairdryerIcon}
              alt='hairdryerIcon'
            />
          </div>
          <span className={styles['name']}>ドライヤー</span>
        </div>
        <div className={styles['item']}>
          <div className={styles['circle']}>
            <img className={styles['icon']} src={bathIcon} alt='bathIcon' />
          </div>
          <span className={styles['name']}>
            お風呂セット
            <br />
            （男女別 共用）
          </span>
        </div>
        <div className={styles['item']}>
          <div className={styles['circle']}>
            <img
              className={styles['icon']}
              src={toothbrushIcon}
              alt='toothbrushIcon'
            />
          </div>
          <span className={styles['name']}>
            歯磨きセット
            <br />
            （有料）
          </span>
        </div>
      </div>
    </section>
  )
}

export default HostelAmenity
