// extracted by mini-css-extract-plugin
export var amenity = "Amenity-module--amenity--522kl";
export var circle = "Amenity-module--circle--Dpr9w";
export var container = "Amenity-module--container--Ibpot";
export var description = "Amenity-module--description--krQiz";
export var fadeDown = "Amenity-module--fadeDown--naqVA";
export var fadeUp = "Amenity-module--fadeUp--h2Rm8";
export var flowing = "Amenity-module--flowing--QJbr3";
export var icon = "Amenity-module--icon--7dYnm";
export var item = "Amenity-module--item--WrRRI";
export var lineIn = "Amenity-module--lineIn--T9FoQ";
export var name = "Amenity-module--name--dWa2n";
export var title = "Amenity-module--title--PGljn";