import * as React from 'react'

import * as styles from '@/components/Hostel/Facility/Facility.module.scss'
import drinkIcon from '@/images/icons/drink.svg'
import kettleIcon from '@/images/icons/kettle.svg'
import outletIcon from '@/images/icons/outlet.svg'
import refrigeratorIcon from '@/images/icons/refrigerator.svg'
import showerIcon from '@/images/icons/shower.svg'
import wifiIcon from '@/images/icons/wifi.svg'

const HostelFacility = () => {
  return (
    <section className={styles['facility']}>
      <h3 className={styles['title']}>facility</h3>
      <p className={styles['description']}>
        こちらは宿泊のお客様が利用できるサービスです。
      </p>
      <div className={styles['container']}>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img className={styles['icon']} src={wifiIcon} alt='wifiIcon' />
          </div>
          <span className={styles['name']}>Wi-Fi</span>
        </div>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img className={styles['icon']} src={outletIcon} alt='outletIcon' />
          </div>
          <span className={styles['name']}>コンセント</span>
        </div>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img className={styles['icon']} src={kettleIcon} alt='kettleIcon' />
          </div>
          <span className={styles['name']}>
            電気ケトル
            <br />
            （共用）
          </span>
        </div>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img
              className={styles['icon']}
              src={refrigeratorIcon}
              alt='refrigeratorIcon'
            />
          </div>
          <span className={styles['name']}>
            小さめの冷蔵庫
            <br />
            （共用）
          </span>
        </div>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img className={styles['icon']} src={drinkIcon} alt='drinkIcon' />
          </div>
          <span className={styles['name']}>
            飲料
            <br />
            ※お茶・コーヒー
            <br />
            のみ無料です。
          </span>
        </div>
        <div className={styles['item']}>
          <div className={styles['square']}>
            <img className={styles['icon']} src={showerIcon} alt='showerIcon' />
          </div>
          <span className={styles['name']}>
            男女別シャワー室
            <br />
            （共用）
          </span>
        </div>
      </div>
      <div className={styles['other']}>
        <span>その他共用設備</span>
        <p>男女別トイレ、洗面台、電子レンジ、駐車場</p>
      </div>
    </section>
  )
}

export default HostelFacility
