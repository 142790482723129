import * as React from 'react'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/navigation'
import '@/styles/swiper.scss'
import * as styles from '@/components/Hostel/Room/Room.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import Parallelogram from '@/components/Path/Parallelogram'
import { useIntersection } from '@/hooks/useIntersection'
import { useRoomImages } from '@/hooks/useRoomImages'
import megane from '@/images/signs/megane.svg'
import tsutsuji from '@/images/signs/tsutsuji.svg'
import urushi from '@/images/signs/urushi.svg'
import washi from '@/images/signs/washi.svg'

type Props = {
  name: 'megane' | 'washi' | 'urushi' | 'tsutsuji'
}

const roomInfo = {
  megane: {
    capacity: '２人まで',
    bed: ['シングル２つ'],
    description:
      '素材を活かし建物のありのままの姿を残したお部屋です。落ち着きのある空間は何かを創造するのに向いています。',
    price: ['1人5,000円～', '2人9,000円～'],
  },
  washi: {
    capacity: '２人まで',
    bed: ['セミダブル１つ'],
    description:
      '伝統工芸である越前和紙を壁一面にあしらった部屋。太陽の光にあたると、漉いた時の水の様子が浮き上がってきます。',
    price: ['1人5,500円～', '2人8,000円～'],
  },
  urushi: {
    capacity: '４人まで',
    bed: ['敷布団４つ'],
    description:
      '和紙畳をあしらった唯一の和室です。広めのお部屋に、お布団を敷いてゆっくりお休みいただけます。',
    price: ['1人7,500円～', '2人9,000円～', '3人12,000円～', '4人14,000円～'],
  },
  tsutsuji: {
    capacity: '３人まで',
    bed: ['シングル２つ', 'ソファベッド１つ'],
    description:
      'かわいらしい照明や家具などが設置された女性優先のお部屋です。ソファはベッドにすることもできます。',
    price: ['1人8,000円～', '2人9,000円～', '3人12,000円～'],
  },
}

const HostelRoom = ({ name }: Props) => {
  const titleRef = React.useRef<HTMLDivElement>(null)
  const viewTitle = useIntersection({ target: titleRef, margin: '-40% 0%' })
  const roomImageList = useRoomImages({ name })

  return (
    <>
      <section className={`${styles['room']} ${styles[name]}`} id={name}>
        <div className={styles['title']} ref={titleRef}>
          <div
            className={`${styles['titleWrap']} ${
              viewTitle ? styles['titleAnim'] : undefined
            }`}
          >
            {name === 'megane' ? (
              <img className={styles['meganeSign']} src={megane} alt='megane' />
            ) : name === 'washi' ? (
              <img className={styles['washiSign']} src={washi} alt='washi' />
            ) : name === 'urushi' ? (
              <img className={styles['urushiSign']} src={urushi} alt='urushi' />
            ) : name === 'tsutsuji' ? (
              <img
                className={styles['tsutsujiSign']}
                src={tsutsuji}
                alt='tsutsuji'
              />
            ) : undefined}
          </div>
        </div>
        <Parallelogram />
        {roomImageList ? (
          <Swiper
            className='slider-parallelogram'
            grabCursor={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={1000}
            loop={true}
            loopedSlides={roomImageList.length}
            navigation={true}
            slidesPerView={'auto'}
            centeredSlides={true}
            modules={[Autoplay, Navigation]}
          >
            {roomImageList.map((roomImage, index) => {
              return <SwiperSlide key={index}>{roomImage}</SwiperSlide>
            })}
          </Swiper>
        ) : undefined}
        <div className={styles['info']}>
          <table className={styles['table']}>
            <tbody>
              <tr>
                <td>定員</td>
                <td>{roomInfo[name].capacity}</td>
              </tr>
              {roomInfo[name].bed.map((bed, index) => {
                return index === 0 ? (
                  <tr key={index}>
                    <td>ベッド</td>
                    <td>{bed}</td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td />
                    <td>{bed}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className={styles['description']}>
            {roomInfo[name].description}
          </div>
        </div>
        <div className={styles['price']}>
          <span className={styles['label']}>料金</span>
          <div className={styles['priceWrap']}>
            <div className={styles['priceItem']}>
              <span className={styles['tag']}>１ 泊</span>
              {roomInfo[name].price.map((price, index) => {
                return (
                  <>
                    {`${price}${
                      roomInfo[name].price.length - 1 !== index ? ' / ' : ''
                    }`}
                    {index % 2 !== 0 && <MediaBreak media='mobile' />}
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <div className={styles['box']} />
    </>
  )
}

export default HostelRoom
