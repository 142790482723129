// extracted by mini-css-extract-plugin
export var description = "Lounge-module--description--RxvO-";
export var divider = "Lounge-module--divider--Yk9Pm";
export var fadeDown = "Lounge-module--fadeDown--Jm8eT";
export var fadeUp = "Lounge-module--fadeUp--VkI9G";
export var flowing = "Lounge-module--flowing--PYujb";
export var image = "Lounge-module--image--DTluU";
export var imageWrap = "Lounge-module--imageWrap--FrO49";
export var lineIn = "Lounge-module--lineIn--BXLCD";
export var lounge = "Lounge-module--lounge--ICkGm";
export var title = "Lounge-module--title--eMhLm";