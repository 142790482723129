import { useRef, useState, useEffect } from 'react'

type Props = {
  target: React.RefObject<Element>
  threshold?: number
  once?: boolean
  margin?: string
}

export const useIntersection = ({
  target,
  threshold = 0,
  once = true,
  margin = '0%',
}: Props) => {
  const [intersecting, setIntersecting] = useState(false)
  const intersectedRef = useRef(false)

  useEffect(() => {
    const element = target.current
    if (element == null) {
      return
    }

    if (once && intersectedRef.current) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIntersecting(entry.isIntersecting)

          if (entry.isIntersecting) {
            intersectedRef.current = true
          }

          if (once && entry.isIntersecting && element != null) {
            observer.unobserve(element)
          }
        })
      },
      {
        rootMargin: margin,
        threshold: threshold,
      }
    )

    observer.observe(element)

    return () => {
      if (once && intersectedRef.current) {
        return
      }
      if (element != null) {
        observer.unobserve(element)
      }
    }
  }, [target, margin, threshold, once])

  return intersecting
}
