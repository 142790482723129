import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

type Props = {
  name: 'megane' | 'washi' | 'urushi' | 'tsutsuji'
}

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}

const roomImageList = {
  megane: [
    <StaticImage
      style={imageStyle}
      src='../images/rooms/megane/1.png'
      alt='Megane-1'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/megane/2.png'
      alt='Megane-2'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/megane/3.png'
      alt='Megane-3'
      placeholder='blurred'
    />,
  ],
  washi: [
    <StaticImage
      style={imageStyle}
      src='../images/rooms/washi/1.png'
      alt='Washi-1'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/washi/2.png'
      alt='Washi-2'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/washi/3.png'
      alt='Washi-3'
      placeholder='blurred'
    />,
  ],
  urushi: [
    <StaticImage
      style={imageStyle}
      src='../images/rooms/urushi/1.png'
      alt='Urushi-1'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/urushi/2.png'
      alt='Urushi-2'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/urushi/3.png'
      alt='Urushi-3'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/urushi/4.png'
      alt='Urushi-4'
      placeholder='blurred'
    />,
  ],
  tsutsuji: [
    <StaticImage
      style={imageStyle}
      src='../images/rooms/tsutsuji/1.png'
      alt='Tsutsuji-1'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/tsutsuji/2.png'
      alt='Tsutsuji-2'
      placeholder='blurred'
    />,
    <StaticImage
      style={imageStyle}
      src='../images/rooms/tsutsuji/3.png'
      alt='Tsutsuji-3'
      placeholder='blurred'
    />,
  ],
}

export const useRoomImages = ({ name }: Props) => {
  return roomImageList[name]
}
