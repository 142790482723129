import scrollTo from 'gatsby-plugin-smoothscroll'
import * as React from 'react'

import * as styles from '@/components/Hostel/Guide/Guide.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import meganeLetter from '@/images/signs/megane_letter.svg'
import meganeSign from '@/images/signs/megane_sign.svg'
import tsutsujiLetter from '@/images/signs/tsutsuji_letter.svg'
import tsutsujiSign from '@/images/signs/tsutsuji_sign.svg'
import urushiLetter from '@/images/signs/urushi_letter.svg'
import urushiSign from '@/images/signs/urushi_sign.svg'
import washiLetter from '@/images/signs/washi_letter.svg'
import washiSign from '@/images/signs/washi_sign.svg'

const HostelGuide = () => {
  return (
    <section className={styles['guide']}>
      <div className={styles['reception']}>
        CHECK IN 15:00
        <span className={styles['desktop']}> / </span>
        <MediaBreak media='mobile' />
        CHECK OUT 10:00
      </div>
      <div className={styles['attention']}>
        ※全館禁煙です。
        <MediaBreak media='mobile' />
        宿泊施設は2階に4部屋あります。
      </div>
      <div className={styles['container']}>
        <div className={styles['item']} onClick={() => scrollTo('#megane')}>
          <img
            className={styles['meganeLetter']}
            src={meganeLetter}
            alt='meganeLetter'
          />
          <span className={styles['title']}>眼鏡</span>
          <img
            className={styles['meganeSign']}
            src={meganeSign}
            alt='meganeSign'
          />
          <div className={styles['arrow']} />
          <button
            className={styles['link']}
            onClick={() => scrollTo('#megane')}
          />
        </div>
        <div className={styles['item']} onClick={() => scrollTo('#washi')}>
          <img
            className={styles['washiLetter']}
            src={washiLetter}
            alt='washiLetter'
          />
          <span className={styles['title']}>和紙</span>
          <img
            className={styles['washiSign']}
            src={washiSign}
            alt='washiSign'
          />
          <div className={styles['arrow']} />
          <button
            className={styles['link']}
            onClick={() => scrollTo('#washi')}
          />
        </div>
        <div className={styles['item']} onClick={() => scrollTo('#urushi')}>
          <img
            className={styles['urushiLetter']}
            src={urushiLetter}
            alt='urushiLetter'
          />
          <span className={styles['title']}>漆</span>
          <img
            className={styles['urushiSign']}
            src={urushiSign}
            alt='urushiSign'
          />
          <div className={styles['arrow']} />
          <button
            className={styles['link']}
            onClick={() => scrollTo('#urushi')}
          />
        </div>
        <div className={styles['item']} onClick={() => scrollTo('#tsutsuji')}>
          <img
            className={styles['tsutsujiLetter']}
            src={tsutsujiLetter}
            alt='tsutsujiLetter'
          />
          <span className={styles['title']}>つつじ</span>
          <img
            className={styles['tsutsujiSign']}
            src={tsutsujiSign}
            alt='tsutsujiSign'
          />
          <div className={styles['arrow']} />
          <button
            className={styles['link']}
            onClick={() => scrollTo('#tsutsuji')}
          />
        </div>
      </div>
    </section>
  )
}

export default HostelGuide
