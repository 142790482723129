import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Hostel/Lounge/Lounge.module.scss'

const HostelLounge = () => {
  return (
    <section className={styles['lounge']}>
      <h3 className={styles['title']}>lounge</h3>
      <p className={styles['description']}>
        宿泊のお客様同士が交流するための共用ラウンジです。
        <br />
        飲食も可能です。ご自由にお楽しみください。
      </p>
      <div className={styles['imageWrap']}>
        <StaticImage
          className={styles['image']}
          src='../../../images/hostel/lounge.png'
          alt='Lounge'
          placeholder='blurred'
        />
      </div>
      <div className={styles['divider']} />
    </section>
  )
}

export default HostelLounge
